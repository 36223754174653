import React, { useState } from "react";
import { Link } from "gatsby";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MenuItems = [
  {
    title: "ホーム",
    url: "/",
    cName: "nav-links",
  },
  {
    title: "プロフィール",
    url: "/about",
    cName: "nav-links",
  },
  {
    title: "アイアンガーヨガについて",
    url: "/iyengar",
    cName: "nav-links",
  },
  {
    title: "お問い合わせ",
    url: "/#contact",
    cName: "nav-links",
  },
];

const Navbar = ({ className }) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <nav className={`navbarItems  ${className ? className : ""}`}>
      <Link to="/">
        <p>ヨガスタジオ笠置</p>
      </Link>

      <div
        className="menu-icon"
        onClick={handleClick}
        onKeyDown={handleClick}
        role="button"
        tabIndex={0}
        aria-label="open menu"
      >
        {clicked ? (
          <FontAwesomeIcon icon={faTimes} style={{ color: "#713E5A" }} />
        ) : (
          <FontAwesomeIcon icon={faBars} style={{ color: "#713E5A" }} />
        )}
      </div>
      <ul className={clicked ? "nav-menu active" : "nav-menu"}>
        {MenuItems.map((item, index) => {
          return (
            <li key={index}>
              <Link className={item.cName} to={item.url}>
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Navbar;
