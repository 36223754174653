import React from "react";
import Footer from "./footer/Footer";
import Navbar from "./nav/Navbar";

const Layout = ({ children, landing }) => {
  return (
    <div
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <Navbar
        className={landing ? "nav-landing" : "nav-landing nav-secondary"}
      />
      {children}
      <Footer className={landing ? null : "footer-secondary"} />
    </div>
  );
};

export default Layout;
