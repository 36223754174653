import { Link } from "gatsby";
import React from "react";
import iyengarLogo from "../../images/iyengar-logo-mini.png";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const InstagramIcon = styled.li`
  a {
    font-size: 1.2rem;
  }
`;
const Footer = () => {
  return (
    <footer>
      <nav>
        <ul className="footer-links">
          <li>
            <Link to={"/about"}>プロフィール</Link>
          </li>
          <li>
            <Link to={"/iyengar"}>アイアンガーヨガとは</Link>
          </li>
          <li>
            <Link to={"/#contact"}>お問い合わせ</Link>
          </li>
          <InstagramIcon>
            <a
              href="https://www.instagram.com/yogastudiokasagi/"
              aria-label="インスタグラムを開く"
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </InstagramIcon>
        </ul>
      </nav>
      <img
        src={iyengarLogo}
        width="40px"
        height="40px"
        alt="iyengar logo"
        className="iyengar-logo"
      />
    </footer>
  );
};

export default Footer;
